<template>
  <a-form :ref="formRef" scrollToFirstError>
    <div>
      <div class="create-new-vacancy-wrapper">
        <div class="create-new-vacancy-text-with-cross-icon">
          <span class="create-company-create-new-vacancy-text">
            Create New Vacancy
          </span>
          <CloseOutlined
            class="create-new-vacancy-cancel-icon"
            @click="onClickExit"
          />
        </div>

        <!-- Company Details section -->
        <div class="create-new-vacancy-company-details">
          <div class="company-and-vacancy-wrapper">
            <div class="common-input-fields-wrapper">
              <div class="common-fields-title-labels">
                Company<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.accountId">
                <a-select
                  allowClear
                  show-search
                  class="select-input"
                  :options="company_name_options"
                  @search="fetchCompanyList"
                  v-model:value="modelRef.accountId"
                  :default-active-first-option="false"
                  :filter-option="false"
                  style="width: 100%"
                  @change="fetchClientContactandTerms"
                >
                </a-select>
              </a-form-item>
            </div>
            <div class="common-input-fields-wrapper" style="margin-right: 0px">
              <div class="common-fields-title-labels">
                Vacancy Title
                <span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.title">
                <a-input
                  class="common-input-fields"
                  v-model:value="modelRef.title"
                />
              </a-form-item>
            </div>
          </div>

          <div class="client-contact-and-city-wrapper">
            <div class="common-input-fields-wrapper">
              <div class="common-fields-title-labels">Client Contact</div>
              <a-form-item>
                <a-select
                  show-Arrow
                  allowClear
                  mode="tags"
                  class="select-input"
                  v-model:value="modelRef.clientPersons"
                  style="width: 100%"
                  :options="client_person_options"
                >
                </a-select>
              </a-form-item>
            </div>
            <div class="sub-team-name-wrapper">
              <div class="common-fields-title-labels">
                City<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.city">
                <a-input
                  class="common-input-fields"
                  v-model:value="modelRef.city"
                />
              </a-form-item>
            </div>
          </div>

          <div class="client-contact-and-city-wrapper">
            <div class="common-input-fields-wrapper">
              <div style="display: flex">
                <div style="width: 50%">
                  <div
                    class="common-fields-title-labels"
                    style="margin-bottom: 14px"
                  >
                    Exp From<span class="all-required-fields-star-mark">*</span>
                  </div>
                  <div style="margin-right: 20px">
                    <a-form-item v-bind="validateInfos.experienceFrom">
                      <a-input
                        class="common-input-fields"
                        v-model:value="modelRef.experienceFrom"
                        @blur="experienceValidation"
                      />
                    </a-form-item>
                  </div>
                </div>
                <div style="width: 50%">
                  <div
                    class="common-fields-title-labels"
                    style="margin-bottom: 14px"
                  >
                    Exp To<span class="all-required-fields-star-mark">*</span>
                  </div>
                  <div>
                    <a-form-item v-bind="validateInfos.experienceTo">
                      <a-input
                        class="common-input-fields"
                        v-model:value="modelRef.experienceTo"
                        @blur="experienceValidation"
                      />
                    </a-form-item>
                  </div>
                </div>
              </div>
            </div>
            <div class="sub-team-name-wrapper">
              <div class="common-fields-title-labels">
                Status<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.vacancyStatus">
                <a-select
                  allowClear
                  class="select-input"
                  v-model:value="modelRef.vacancyStatus"
                  style="width: 100%"
                  :options="vacancyStatusesResponse"
                >
                </a-select>
              </a-form-item>
            </div>
          </div>

          <div class="client-contact-and-city-wrapper">
            <div class="common-input-fields-wrapper">
              <div class="common-fields-title-labels">
                Vacancy Type<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.vacancyType">
                <a-select
                  allowClear
                  class="select-input"
                  v-model:value="modelRef.vacancyType"
                  style="width: 100%"
                  :options="vacancyTypeResponse"
                >
                </a-select>
              </a-form-item>
            </div>
            <div class="sub-team-name-wrapper">
              <div class="common-input-fields-wrapper">
                <div style="display: flex">
                  <div style="width: 50%">
                    <div
                      class="common-fields-title-labels"
                      style="margin-bottom: 14px"
                    >
                      Originated By
                      <span class="all-required-fields-star-mark">*</span>
                    </div>
                    <div style="margin-right: 20px">
                      <a-form-item v-bind="validateInfos.originatedBy">
                        <a-select
                          allowClear
                          class="select-input"
                          v-model:value="modelRef.originatedBy"
                          style="width: 100%"
                          :options="originatedByResponse"
                          @change="originatedByChange"
                        >
                        </a-select>
                      </a-form-item>
                    </div>
                  </div>
                  <div style="width: 50%">
                    <div
                      class="common-fields-title-labels"
                      style="margin-bottom: 14px"
                    >
                      Originated CIC
                    </div>
                    <div>
                      <a-form-item>
                        <a-select
                          allowClear
                          show-search
                          class="select-input"
                          :options="cicOptions"
                          @search="fetchCicList"
                          v-model:value="modelRef.originatedCIC"
                          :default-active-first-option="false"
                          :show-arrow="false"
                          :filter-option="false"
                          :disabled="modelRef.originatedBy === 'Franchise'"
                        >
                        </a-select>
                      </a-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Additional Details Section  -->

        <div class="create-new-vacancy-company-details">
          <div class="create-vacancy-additional-details-section">
            Additional Details
          </div>

          <div class="client-contact-and-city-wrapper" style="margin-top: 17px">
            <div class="common-input-fields-wrapper">
              <div class="common-fields-title-labels">
                Level<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.levelId">
                <a-select
                  allowClear
                  class="select-input"
                  v-model:value="modelRef.levelId"
                  style="width: 100%"
                  placeholder="Select"
                  :options="designationLevelOptions"
                >
                </a-select>
              </a-form-item>
            </div>
            <div class="sub-team-name-wrapper">
              <div class="common-fields-title-labels">
                Fee%<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.termId">
                <a-select
                  allowClear
                  class="select-input"
                  v-model:value="modelRef.termId"
                  style="width: 100%"
                  :options="termsFeesOptions"
                  @change="updateValue"
                >
                </a-select>
              </a-form-item>
            </div>
          </div>

          <div class="company-and-vacancy-wrapper" style="margin-top: 0px">
            <div class="common-input-fields-wrapper">
              <div class="common-fields-title-labels">
                Salary<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.salaryFrom">
                <a-input
                  class="common-input-fields"
                  placeholder="Enter"
                  v-model:value="modelRef.salaryFrom"
                  @change="updateValue"
                />
              </a-form-item>
            </div>
            <div class="common-input-fields-wrapper" style="margin-right: 0px">
              <div class="common-fields-title-labels">Value</div>
              <a-form-item>
                <a-input
                  class="common-input-fields"
                  placeholder="Enter"
                  v-model:value="modelRef.value"
                />
              </a-form-item>
            </div>
          </div>

          <div class="client-contact-and-city-wrapper">
            <div class="sub-team-name-wrapper" style="margin-right: 30px">
              <div
                class="common-fields-title-labels"
                style="margin-bottom: 14px"
              >
                Start Date<span class="all-required-fields-star-mark">*</span>
              </div>
              <div>
                <a-form-item v-bind="validateInfos.startDate">
                  <a-date-picker
                    class="date-input"
                    :disabled-date="disabledStartDate"
                    v-model:value="modelRef.startDate"
                  />
                </a-form-item>
              </div>
            </div>
            <div class="common-input-fields-wrapper" style="margin-right: 0px">
              <div style="display: flex">
                <div style="width: 50%">
                  <div
                    class="common-fields-title-labels"
                    style="margin-bottom: 14px"
                  >
                    Display Start Date
                  </div>
                  <div style="margin-right: 20px">
                    <a-form-item>
                      <a-date-picker
                        class="date-input"
                        :disabled-date="disabledStartDate"
                        v-model:value="modelRef.displayStartDate"
                      />
                    </a-form-item>
                  </div>
                </div>
                <div style="width: 50%">
                  <div
                    class="common-fields-title-labels"
                    style="margin-bottom: 14px"
                  >
                    Display End Date
                  </div>
                  <div>
                    <a-form-item>
                      <a-date-picker
                        class="date-input"
                        :disabled-date="disabledEndDate"
                        v-model:value="modelRef.displayEndDate"
                      />
                    </a-form-item>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="client-contact-and-city-wrapper" style="margin-top: 17px">
            <div class="common-input-fields-wrapper">
              <div class="common-fields-title-labels">
                Vertical<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.verticals">
                <a-select
                  class="select-input"
                  v-model:value="modelRef.verticals"
                  style="width: 100%"
                  show-search
                  allowClear
                  :default-active-first-option="false"
                  :filter-option="false"
                  :options="verticalResponse"
                  @search="fetchVerticalData"
                  @change="fetchpracticeAreas('', modelRef.verticals)"
                >
                </a-select>
              </a-form-item>
            </div>
            <div class="sub-team-name-wrapper">
              <div class="common-fields-title-labels">
                PA <span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.practiceAreas">
                <a-select
                  class="select-input"
                  v-model:value="modelRef.practiceAreas"
                  style="width: 100%"
                  show-search
                  allowClear
                  :default-active-first-option="false"
                  :filter-option="false"
                  :options="practiceAreaResponse"
                  @search="fetchpracticeAreas"
                  @change="fetchsubPracticeAreas('', modelRef.practiceAreas)"
                >
                </a-select>
              </a-form-item>
            </div>
          </div>
          <div class="client-contact-and-city-wrapper" style="width: 50%">
            <div class="common-input-fields-wrapper" style="margin-right: 15px">
              <div class="common-fields-title-labels">SPA</div>
              <a-form-item>
                <a-select
                  class="select-input"
                  v-model:value="modelRef.subPracticeAreas"
                  style="width: 100%"
                  show-search
                  allowClear
                  :default-active-first-option="false"
                  :filter-option="false"
                  :options="subPracticeAreaResponse"
                  @search="fetchsubPracticeAreas"
                >
                </a-select>
              </a-form-item>
            </div>
          </div>

          <div class="client-contact-and-city-wrapper">
            <div class="common-input-fields-wrapper" style="margin-right: 00px">
              <div class="common-fields-title-labels">Job Profile</div>
              <a-form-item>
                <a-textarea
                  placeholder="Autosize height based on content lines"
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                  v-model:value="modelRef.jobProfileDesc"
                />
              </a-form-item>
            </div>
          </div>

          <div class="client-contact-and-city-wrapper">
            <div class="common-input-fields-wrapper" style="margin-right: 00px">
              <div class="common-fields-title-labels">Candidate Profile</div>
              <a-form-item>
                <a-textarea
                  placeholder="Autosize height based on content lines"
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                  v-model:value="modelRef.candProfileDesc"
                />
              </a-form-item>
            </div>
          </div>
        </div>

        <!-- Assigned To  -->

        <div class="create-new-vacancy-company-details">
          <div class="create-vacancy-additional-details-section">
            Assigned To
          </div>
          <div class="cic-section">
            <div
              class="client-contact-and-city-wrapper"
              style="margin-top: 17px"
            >
              <div class="common-input-fields-wrapper">
                <div class="common-fields-title-labels">
                  Lead CIC<span class="all-required-fields-star-mark">*</span>
                </div>
                <a-form-item v-bind="validateInfos.leadCIC">
                  <a-select
                    allowClear
                    show-search
                    class="select-input"
                    style="width: 100%"
                    :options="cicOptions"
                    @search="fetchCicList"
                    v-model:value="modelRef.leadCIC"
                    :default-active-first-option="false"
                    :filter-option="false"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="sub-team-name-wrapper">
                <div
                  class="common-fields-title-labels"
                  style="margin-bottom: 12px"
                >
                  Revenue Share
                </div>
                <a-form-item>
                  <a-input
                    class="common-input-fields"
                    v-model:value="cicRef.leadRevShare"
                    @blur="manageCICRevenueShare(0)"
                  />
                </a-form-item>
              </div>
            </div>

            <div class="client-contact-and-city-wrapper">
              <div class="common-input-fields-wrapper">
                <div class="common-fields-title-labels">CIC2</div>
                <a-form-item>
                  <a-select
                    allowClear
                    show-search
                    class="select-input"
                    :options="cicOptions"
                    @search="fetchCicList"
                    v-model:value="cicRef.CIC1Id"
                    :default-active-first-option="false"
                    style="width: 100%"
                    :filter-option="false"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="sub-team-name-wrapper">
                <div
                  class="common-fields-title-labels"
                  style="margin-bottom: 12px; color: #b3b1b4"
                >
                  Revenue Share
                </div>
                <a-form-item>
                  <a-input
                    class="common-input-fields"
                    placeholder="Enter"
                    v-model:value="cicRef.RevShare1"
                    :disabled="disableCICRev1"
                    @blur="manageCICRevenueShare(1)"
                  />
                </a-form-item>
              </div>
            </div>

            <div class="client-contact-and-city-wrapper">
              <div class="common-input-fields-wrapper">
                <div class="common-fields-title-labels">CIC3</div>
                <a-form-item>
                  <a-select
                    allowClear
                    show-search
                    class="select-input"
                    :options="cicOptions"
                    @search="fetchCicList"
                    v-model:value="cicRef.CIC2Id"
                    :default-active-first-option="false"
                    style="width: 100%"
                    :filter-option="false"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="sub-team-name-wrapper">
                <div
                  class="common-fields-title-labels"
                  style="margin-bottom: 12px; color: #b3b1b4"
                >
                  Revenue Share
                </div>
                <a-form-item>
                  <a-input
                    class="common-input-fields"
                    placeholder="Enter"
                    v-model:value="cicRef.RevShare2"
                    :disabled="disableCICRev2"
                    @blur="manageCICRevenueShare(2)"
                  />
                </a-form-item>
              </div>
            </div>

            <div
              class="client-contact-and-city-wrapper"
              style="border-bottom: 1px solid rgb(227, 227, 227)"
            >
              <div class="common-input-fields-wrapper">
                <div class="common-fields-title-labels">CIC4</div>
                <a-form-item>
                  <a-select
                    allowClear
                    show-search
                    class="select-input"
                    :options="cicOptions"
                    @search="fetchCicList"
                    v-model:value="cicRef.CIC3Id"
                    :default-active-first-option="false"
                    style="width: 100%"
                    :filter-option="false"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="sub-team-name-wrapper">
                <div
                  class="common-fields-title-labels"
                  style="margin-bottom: 12px; color: #b3b1b4"
                >
                  Revenue Share
                </div>
                <a-form-item>
                  <a-input
                    class="common-input-fields"
                    placeholder="Enter"
                    v-model:value="cicRef.RevShare3"
                    :disabled="disableCICRev3"
                    @blur="manageCICRevenueShare(3)"
                  />
                </a-form-item>
              </div>
            </div>
          </div>
          <div class="team-subTeam-section">
            <div
              class="client-contact-and-city-wrapper"
              style="margin-top: 17px"
            >
              <div class="common-input-fields-wrapper">
                <div class="common-fields-title-labels">
                  Team 1<span class="all-required-fields-star-mark">*</span>
                </div>
                <a-form-item v-bind="validateInfos.team1Id">
                  <a-select
                    show-search
                    allowClear
                    class="select-input"
                    style="width: 100%"
                    v-model:value="modelRef.team1Id"
                    :filter-option="false"
                    :options="teamOptions"
                    @search="fetchTeamsData"
                    @change="fetchSubTeamsData(modelRef.team1Id, 1)"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="sub-team-name-wrapper">
                <div class="common-fields-title-labels">
                  Sub Team 1<span class="all-required-fields-star-mark">*</span>
                </div>
                <a-form-item v-bind="validateInfos.subteam1Id">
                  <a-select
                    allowClear
                    class="select-input"
                    style="width: 100%"
                    v-model:value="modelRef.subteam1Id"
                    :options="subTeamOptions"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <div class="client-contact-and-city-wrapper" style="width: 50%">
              <div
                class="common-input-fields-wrapper"
                style="margin-right: 15px"
              >
                <div class="common-fields-title-labels">Revenue Share</div>
                <a-form-item>
                  <a-input
                    class="common-input-fields"
                    v-model:value="teamRef.revShare1"
                    @blur="manageTeamRevenueShare(1)"
                  />
                </a-form-item>
              </div>
            </div>

            <div class="client-contact-and-city-wrapper">
              <div class="common-input-fields-wrapper">
                <div class="common-fields-title-labels">Team 2</div>
                <a-form-item>
                  <a-select
                    show-search
                    allowClear
                    class="select-input"
                    style="width: 100%"
                    v-model:value="teamRef.team2Id"
                    :filter-option="false"
                    :options="teamOptions"
                    @search="fetchTeamsData"
                    @change="fetchSubTeamsData(teamRef.team2Id, 2)"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="sub-team-name-wrapper">
                <div class="common-fields-title-labels">Sub Team 2</div>
                <a-form-item>
                  <a-select
                    allowClear
                    class="select-input"
                    style="width: 100%"
                    v-model:value="teamRef.subteam2Id"
                    :options="subTeamOptions"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <div class="client-contact-and-city-wrapper" style="width: 50%">
              <div
                class="common-input-fields-wrapper"
                style="margin-right: 15px"
              >
                <div class="common-fields-title-labels" style="color: #b3b1b4">
                  Revenue Share
                </div>
                <a-form-item>
                  <a-input
                    class="common-input-fields"
                    v-model:value="teamRef.revShare2"
                    @blur="manageTeamRevenueShare(2)"
                    :disabled="disableTeamRev2"
                  />
                </a-form-item>
              </div>
            </div>

            <div class="client-contact-and-city-wrapper">
              <div class="common-input-fields-wrapper">
                <div class="common-fields-title-labels">Team 3</div>
                <a-form-item>
                  <a-select
                    show-search
                    allowClear
                    class="select-input"
                    style="width: 100%"
                    v-model:value="teamRef.team3Id"
                    :filter-option="false"
                    :options="teamOptions"
                    @search="fetchTeamsData"
                    @change="fetchSubTeamsData(teamRef.team3Id, 3)"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="sub-team-name-wrapper">
                <div class="common-fields-title-labels">Sub Team 3</div>
                <a-form-item>
                  <a-select
                    allowClear
                    class="select-input"
                    style="width: 100%"
                    v-model:value="teamRef.subteam3Id"
                    :options="subTeamOptions"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="client-contact-and-city-wrapper" style="width: 50%">
              <div
                class="common-input-fields-wrapper"
                style="margin-right: 15px"
              >
                <div class="common-fields-title-labels" style="color: #b3b1b4">
                  Revenue Share
                </div>
                <a-form-item>
                  <a-input
                    class="common-input-fields"
                    v-model:value="teamRef.revShare3"
                    @blur="manageTeamRevenueShare(3)"
                    :disabled="disableTeamRev3"
                  />
                </a-form-item>
              </div>
            </div>
          </div>
        </div>

        <!-- Documents upload section -->

        <div class="create-new-vacancy-company-details">
          <span
            class="create-vacancy-additional-details-section"
            style="margin-bottom: 10px"
          >
            Documents
          </span>
          <span class="create-vacancy-optional-text"> (optional)</span>
          <div class="upload-component" style="width: 70%; margin: auto">
            <div class="create-new-vacancy-attach-file-text">Attach Files</div>
            <a-upload-dragger
              :multiple="true"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              name="file"
              style="border-radius: 15px; border-color: rgba(229, 129, 106, 1)"
            >
              <p class="ant-upload-drag-icon">
                <CloudUploadOutlined style="color: rgba(229, 129, 106, 1)" />
              </p>
              <p class="ant-upload-text">
                Drag & drop or Click on this area to upload file
              </p>
            </a-upload-dragger>
          </div>
        </div>

        <div class="create-vacancy-exit-and-creat-button-wrapper">
          <div class="create-vacancy-exit-btn-text" @click="onClickExit">
            Exit
          </div>
          <div class="create-vacancy-button-wrapper">
            <a-button
              type="primary"
              class="create-vacancy-button"
              @click="onSubmit"
            >
              Create Vacancy
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </a-form>
</template>
<script>
import { defineComponent, reactive, ref, onMounted } from "vue";
import { Form, message } from "ant-design-vue";
import Services from "@/services/apis";
import { useRouter } from "vue-router";
import Utils from "../../../utils";
import { CloseOutlined, CloudUploadOutlined } from "@ant-design/icons-vue";
const disableCICRev1 = ref(true);
const disableCICRev2 = ref(true);
const disableCICRev3 = ref(true);
const disableTeamRev2 = ref(true);
const disableTeamRev3 = ref(true);
const useForm = Form.useForm;

export default defineComponent({
  components: {
    CloseOutlined,
    CloudUploadOutlined,
  },

  setup() {
    const router = useRouter();
    const formRef = ref();
    let paData;
    let subPaData;
    
    const modelRef = reactive({
      accountId: "",
      title: "",
      clientPersons: [],
      city: "",
      experienceTo: "",
      experienceFrom: "",
      vacancyStatus: "",
      vacancyType: "",
      originatedBy: "Franchise",
      originatedCIC: "",
      levelId: "",
      termId: "",
      salaryFrom: "",
      salaryTo: "",
      value: "",
      startDate: "",
      endDate: "",
      displayStartDate: "",
      displayEndDate: "",
      verticals: undefined,
      practiceAreas: undefined,
      subPracticeAreas: undefined,
      jobProfileDesc: "",
      candProfileDesc: "",
      leadCIC: "",
      team1Id: "",
      subteam1Id: "",
      cic: [
        {
          userId: "",
          isLead: true,
          revShare: 0,
        },
      ],
      teams: [
        {
          teamId: "",
          subteamId: "",
          revShare: 0,
        },
      ],
      documents: [
        {
          name: "",
          type: "",
          url: "",
          size: 0,
        },
      ],
      instructions: "",
      vacancyCount: 0,
    });
    let cityRespones = ref([]);
    let company_name_options = ref([]);
    let client_person_options = ref([]);
    let vacancyTypeResponse = ref([]);
    let vacancyStatusesResponse = ref([]);
    let originatedByResponse = ref([
      { value: "Individual", label: "Individual" },
      { value: "Franchise", label: "Franchise" },
    ]);
    let cicOptions = ref([]);
    let verticalResponse = ref([]);
    let practiceAreaResponse = ref([]);
    let subPracticeAreaResponse = ref([]);
    let designationLevelOptions = ref([]);
    let termsFeesOptions = ref([]);
    let teamOptions = ref([]);
    let subTeamOptions = ref([]);

    let termsData = [];
    const rulesRef = reactive({
      accountId: [
        {
          required: true,
          message: "Please enter company name",
        },
      ],
      title: [
        {
          required: true,
          message: "Please enter vacancy title",
        },
      ],
      city: [
        {
          required: true,
          message: "Please select city",
        },
      ],
      experienceFrom: [
        {
          required: true,
          message: "Please select experience from",
        },
      ],
      experienceTo: [
        {
          required: true,
          message: "Please select experience to",
        },
      ],
      vacancyStatus: [
        {
          required: true,
          message: "Please select status",
        },
      ],
      originatedBy: [
        {
          required: true,
          message: "Please select Originated By",
        },
      ],
      vacancyType: [
        {
          required: true,
          message: "Please select vacancy type",
        },
      ],
      levelId: [
        {
          required: true,
          message: "Please select Level",
        },
      ],
      termId: [
        {
          required: true,
          message: "Please select a Fee",
        },
      ],
      salaryFrom: [
        {
          required: true,
          message: "Please enter salary",
        },
      ],
      startDate: [
        {
          required: true,
          message: "Please select start date",
        },
      ],
      verticals: [
        {
          required: true,
          message: "Please select vertical",
        },
      ],
      practiceAreas: [
        {
          required: true,
          message: "Please select practice area",
        },
      ],
      leadCIC: [
        {
          required: true,
          message: "Please select a Lead CIC for the Vacancy",
        },
      ],
      team1Id: [
        {
          required: true,
          message: "Please select Team",
        },
      ],
      subteam1Id: [
        {
          required: true,
          message: "Please select Sub Team",
        },
      ],
    });

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    );

    let cicRef = reactive({
      leadRevShare: 100,
      CIC1Id: "",
      RevShare1: 0,
      CIC2Id: "",
      RevShare2: 0,
      CIC3Id: "",
      RevShare3: 0,
      cicList: [],
      cicRevenueShareTotal: 0,
    });

    let teamRef = reactive({
      revShare1: 100,
      team2Id: "",
      subteam2Id: "",
      revShare2: 0,
      team3Id: "",
      subteam3Id: "",
      revShare3: 0,
      teamRevenueShareTotal: 0,
      teamList: [],
    });

    // Function to manage CIC revenue Share logic as per input from each cic's revenue share
    const manageCICRevenueShare = (cic) => {
      let leadShare =
        parseInt(cicRef.leadRevShare) > 0 ? parseInt(cicRef.leadRevShare) : 0;
      let cic1Share =
        parseInt(cicRef.RevShare1) > 0 ? parseInt(cicRef.RevShare1) : 0;
      let cic2Share =
        parseInt(cicRef.RevShare2) > 0 ? parseInt(cicRef.RevShare2) : 0;
      let cic3Share =
        parseInt(cicRef.RevShare3) > 0 ? parseInt(cicRef.RevShare3) : 0;
      if (cic === 0) {
        if (leadShare > 100) {
          cicRef.leadRevShare = 100;
          leadShare = parseInt(cicRef.leadRevShare);
          message.error("Revenue Share can not be more than 100%");
        } else {
          cicRef.leadRevShare = leadShare;
        }
        cicRef.cicRevenueShareTotal = leadShare;
        disableCICRev1.value = cicRef.cicRevenueShareTotal >= 100;
        cicRef.RevShare1 = 100 - leadShare;
      } else if (cic === 1) {
        cicRef.cicRevenueShareTotal = leadShare + cic1Share;
        if (cicRef.cicRevenueShareTotal > 100) {
          message.error("Revenue Share can not be more than 100%");
          cicRef.RevShare1 = 100 - leadShare;
          cic1Share = parseInt(cicRef.RevShare1);
          cicRef.cicRevenueShareTotal = leadShare + cic1Share;
        } else {
          cicRef.RevShare1 = cic1Share;
          cicRef.cicRevenueShareTotal = leadShare + cic1Share;
        }
        disableCICRev2.value = cicRef.cicRevenueShareTotal >= 100;
        cicRef.RevShare2 = 100 - cicRef.cicRevenueShareTotal;
      } else if (cic === 2) {
        cicRef.cicRevenueShareTotal = leadShare + cic1Share + cic2Share;
        if (cicRef.cicRevenueShareTotal > 100) {
          message.error("Revenue Share can not be more than 100%");
          cicRef.RevShare2 = 100 - (leadShare + cic1Share);
          cic2Share = parseInt(cicRef.RevShare2);
          cicRef.cicRevenueShareTotal = leadShare + cic1Share + cic2Share;
        } else {
          cicRef.RevShare2 = cic2Share;
          cicRef.cicRevenueShareTotal = leadShare + cic1Share + cic2Share;
        }
        disableCICRev3.value = cicRef.cicRevenueShareTotal >= 100;
        cicRef.RevShare3 = 100 - cicRef.cicRevenueShareTotal;
      } else if (cic === 3) {
        cicRef.cicRevenueShareTotal =
          leadShare + cic1Share + cic2Share + cic3Share;
        if (cicRef.cicRevenueShareTotal > 100) {
          message.error("Revenue Share can not be more than 100%");
          cicRef.RevShare3 = 100 - (leadShare + cic1Share + cic2Share);
          cic3Share = parseInt(cicRef.RevShare3);
          cicRef.cicRevenueShareTotal =
            leadShare + cic1Share + cic2Share + cic3Share;
        } else {
          cicRef.RevShare3 = cic3Share;
          cicRef.cicRevenueShareTotal =
            leadShare + cic1Share + cic2Share + cic3Share;
        }
      }
    };

    const manageTeamRevenueShare = (team) => {
      let team1Share =
        parseInt(teamRef.revShare1) > 0 ? parseInt(teamRef.revShare1) : 0;
      let team2Share =
        parseInt(teamRef.revShare2) > 0 ? parseInt(teamRef.revShare2) : 0;
      let team3Share =
        parseInt(teamRef.revShare3) > 0 ? parseInt(teamRef.revShare3) : 0;
      if (team === 1) {
        if (team1Share > 100) {
          teamRef.revShare1 = 100;
          message.error("Revenue Share can not be more than 100%");
          team1Share = parseInt(teamRef.revShare1);
        } else {
          teamRef.revShare1 = team1Share;
        }
        teamRef.teamRevenueShareTotal = team1Share;
        disableTeamRev2.value = teamRef.teamRevenueShareTotal >= 100;
        teamRef.revShare2 = 100 - team1Share;
      } else if (team === 2) {
        teamRef.teamRevenueShareTotal = team1Share + team2Share;
        if (teamRef.teamRevenueShareTotal > 100) {
          message.error("Revenue Share can not be more than 100%");
          teamRef.revShare2 = 100 - team1Share;
          team2Share = parseInt(teamRef.revShare2);
          teamRef.teamRevenueShareTotal = team1Share + team2Share;
        } else {
          teamRef.revShare2 = team2Share;
          teamRef.teamRevenueShareTotal = team1Share + team2Share;
        }
        disableTeamRev3.value = teamRef.teamRevenueShareTotal >= 100;
        teamRef.revShare3 = 100 - teamRef.teamRevenueShareTotal;
      } else if (team === 3) {
        teamRef.teamRevenueShareTotal = team1Share + team2Share + team3Share;
        if (teamRef.teamRevenueShareTotal > 100) {
          message.error("Revenue Share can not be more than 100%");
          teamRef.revShare3 = 100 - (team1Share + team2Share);
          team3Share = parseInt(teamRef.revShare3);
          teamRef.teamRevenueShareTotal = team1Share + team2Share + team3Share;
        } else {
          teamRef.revShare3 = team3Share;
          teamRef.teamRevenueShareTotal = team1Share + team2Share + team3Share;
        }
      }
    };

    const updateCICList = () => {
      let cicObject;
      if (modelRef.leadCIC) {
        cicObject = {
          userId: modelRef.leadCIC,
          revShare: parseInt(cicRef.leadRevShare),
          isLead: true,
        };
        cicRef.cicList.push(cicObject);
      }
      if (cicRef.CIC1Id) {
        cicObject = {
          userId: cicRef.CIC1Id,
          revShare: parseInt(cicRef.RevShare1),
          isLead: false,
        };
        cicRef.cicList.push(cicObject);
      }
      if (cicRef.CIC2Id) {
        cicObject = {
          userId: cicRef.CIC2Id,
          revShare: parseInt(cicRef.RevShare2),
          isLead: false,
        };
        cicRef.cicList.push(cicObject);
      }
      if (cicRef.CIC3Id) {
        cicObject = {
          userId: cicRef.CIC3Id,
          revShare: parseInt(cicRef.RevShare3),
          isLead: false,
        };
        cicRef.cicList.push(cicObject);
      }
    };

    const updateTeamList = () => {
      let teamObject;
      teamObject = {
        teamId: modelRef.team1Id,
        subTeamId: modelRef.subteam1Id,
        revShare: parseInt(teamRef.revShare1),
      };
      teamRef.teamList.push(teamObject);
      if (teamRef.team2Id) {
        if (teamRef.subteam2Id) {
          teamObject = {
            teamId: teamRef.team2Id,
            subTeamId: teamRef.subteam2Id,
            revShare: parseInt(teamRef.revShare2),
          };
        } else {
          message.error("Please Select Sub Team 2");
        }
        teamRef.teamList.push(teamObject);
      }
      if (teamRef.team3Id) {
        if (teamRef.subteam3Id) {
          teamObject = {
            teamId: teamRef.team3Id,
            subTeamId: teamRef.subteam3Id,
            revShare: parseInt(teamRef.revShare3),
          };
        } else {
          message.error("Please Select Sub Team 3");
        }
        teamRef.teamList.push(teamObject);
      }
      teamRef.teamRevenueShareTotal =
        parseInt(teamRef.revShare1) +
        parseInt(teamRef.revShare2) +
        parseInt(teamRef.revShare3);
    };

    const fetchCompanyList = async (value) => {
      company_name_options.value.splice(0);
      let tempData = [];
      termsData = [];

      await Services.getAccountListing({ q: value, mode: "AUTOCOMPLETE" })
        .then((response) => {
          const { data } = response;
          data.map((x) => {
            tempData.push({
              value: x.account.id,
              label: x.account.name,
            });
            Object.assign(company_name_options.value, tempData);
          });
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchVacancyType = () => {
      Services.getVacancyTypeDetail({
        mode: "AUTOCOMPLETE",
        types: ["VACANCY_TYPES"],
      })
        .then((response) => {
          vacancyTypeResponse.value = response.data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const fetchVacancyStatuses = async () => {
      await Services.getStageDetail({
        mode: "AUTOCOMPLETE",
        types: ["VACANCY"],
      })
        .then((response) => {
          vacancyStatusesResponse.value = response.data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
      let vacancyStatusArray = vacancyStatusesResponse.value.filter(
        (vacancyStatus) => vacancyStatus.label === "Running"
      );
      modelRef.vacancyStatus = vacancyStatusArray[0].value;
    };

    const fetchVerticalData = (value) => {
      Services.getVerticalMasterDetail({ mode: "AUTOCOMPLETE", q: value })
        .then((response) => {
          verticalResponse.value = response.data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const fetchpracticeAreas = (value, id) => {
      if (id) {
        paData = id;
      }
      Services.getPracticeAreaDetail({
        mode: "AUTOCOMPLETE",
        q: value,
        types: ["PRACTICE_AREA"],
        verticals: paData ? paData : null,
      })
        .then((response) => {
          practiceAreaResponse.value = response.data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const fetchsubPracticeAreas = (value,id) => {
      if (id) {
        subPaData = id;
      }
      Services.getSubPracticeAreaDetail({
        mode: "AUTOCOMPLETE",
        types: ["SUB_PRACTICE_AREA"],
        practice_areas : subPaData ? subPaData : null,
        q: value,
      })
        .then((response) => {
          subPracticeAreaResponse.value = response.data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const fetchDesignationLevels = (value) => {
      Services.getlevelDetail({
        mode: "AUTOCOMPLETE",
        types: ["DESIGNATION"],
        q: value,
      })
        .then((response) => {
          designationLevelOptions.value = response.data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const fetchCicList = (value) => {
      Services.userListing({
        q: value,
        pageNo: 0,
        mode: "AUTOCOMPLETE",
      })
        .then((response) => {
          const { data } = response;
          cicOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchClientContactandTerms = (value) => {
      let tempData = [];
      termsData = [];
      modelRef.termId = "";
      modelRef.clientPersons = [];
      client_person_options.value.splice(0);
      termsFeesOptions.value.splice(0);
      Services.getClientPersonListing({
        companyId: value,
        pageNo: 0,
        mode: "AUTOCOMPLETE",
      })
        .then((response) => {
          const { data } = response;
          data[0].count &&
            data[0].client_persons.map((x) => {
              tempData.push({
                label: x.user.name,
                value: x.user.id,
              });
            });
          Object.assign(client_person_options.value, tempData);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      // API call to fetch Terms

      Services.getAccountTerms(value)
        .then((response) => {
          if (response.status === 200) {
            let { data } = response;
            if (data.length > 0) {
              data &&
                data.map((term) => {
                  termsData.push({
                    id: term.id,
                    level: term.name,
                    isFixed: term.isFixed,
                    fee: term.fee,
                    percentage: term.percentage,
                    fixedFee: term.fixedFee ? term.fixedFee : 10000,
                  });
                });
            }
            let tempData = Utils.termArray(termsData);
            Object.assign(termsFeesOptions.value, tempData);
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchTeamsData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["TEAM"] };
      Services.teamListing(payload)
        .then((response) => {
          const { data } = response;
          teamOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubTeamsData = (value, team) => {
      if (team === 1) {
        modelRef.subteam1Id = "";
      }
      if (team === 2) {
        teamRef.subteam2Id = "";
      }
      if (team === 3) {
        teamRef.subteam3Id = "";
      }
      Services.teamListing({
        mode: "AUTOCOMPLETE",
        types: ["SUB_TEAM"],
        teams: value,
      })
        .then((response) => {
          const { data } = response.data;
          if (data.length) {
            subTeamOptions.value = data.map(
              ({ id: value, name: label, ...rest }) => ({
                value,
                label,
                ...rest,
              })
            );
          } else {
            subTeamOptions.value = teamOptions.value.filter(
              (team) => team.value === value
            );
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const updateValue = () => {
      if (/^\d+$/.test(modelRef.salaryFrom) && modelRef.termId) {
        let salary = modelRef.salaryFrom;
        let selectedTermId = modelRef.termId;
        let termArray = termsData.filter((term) => term.id === selectedTermId);
        let term = termArray[0];
        if (term.isFixed) {
          modelRef.value = term.fixedFee;
        } else {
          let value = (salary * term.percentage) / 100;
          if (term.fee && value > term.fee) {
            value = term.fee;
          }
          modelRef.value = value;
        }
      }
    };

    const experienceValidation = () => {
      if (
        modelRef.experienceFrom &&
        modelRef.experienceTo &&
        parseInt(modelRef.experienceFrom) > parseInt(modelRef.experienceTo)
      ) {
        message.error(
          "Experience From value need to be smaller than Experience To value"
        );
        return false;
      }
      return true;
    };

    const disabledStartDate = (startValue) => {
      if (!startValue || !modelRef.displayEndDate) {
        return false;
      }

      return startValue.valueOf() > modelRef.displayEndDate.valueOf();
    };

    const disabledEndDate = (endValue) => {
      console.log("endValue :>> ", endValue);
      if (!endValue || !modelRef.displayStartDate) {
        return false;
      }

      return modelRef.displayStartDate.valueOf() >= endValue.valueOf();
    };

    const originatedByChange = (value) => {
      if (value === "Franchise") {
        modelRef.originatedCIC = "";
      }
    };

    onMounted(() => {
      fetchVacancyType();
      fetchVacancyStatuses();
      fetchVerticalData();
      fetchpracticeAreas();
      fetchsubPracticeAreas();
      fetchDesignationLevels();
      fetchCompanyList();
      fetchCicList();
      fetchTeamsData();
    });

    const onClickExit = () => {
      router.push(`/vacancy/vacancy-list`);
    };

    let customValidation = () => {
      let validate = true;
      cicRef.cicRevenueShareTotal =
        parseInt(cicRef.leadRevShare) +
        parseInt(cicRef.RevShare1) +
        parseInt(cicRef.RevShare2) +
        parseInt(cicRef.RevShare3);
      teamRef.teamRevenueShareTotal =
        parseInt(teamRef.revShare1) +
        parseInt(teamRef.revShare2) +
        parseInt(teamRef.revShare3);
      if (
        modelRef.originatedBy === "Individual" &&
        modelRef.originatedCIC === ""
      ) {
        message.error("Please Select Originated By CIC");
        validate = false;
      }
      if (
        cicRef.cicRevenueShareTotal < 100 ||
        teamRef.teamRevenueShareTotal < 100
      ) {
        message.error("Revenue Share cannot be less than 100");
        validate = false;
      }
      if (
        cicRef.cicRevenueShareTotal > 100 ||
        teamRef.teamRevenueShareTotal > 100
      ) {
        message.error("Revenue Share cannot be more than 100");
        validate = false;
      }
      if (!experienceValidation()) {
        validate = false;
      }
      return validate;
    };

    const onSubmit = () => {
      if (customValidation()) {
        validate()
          .then((result) => {
            updateCICList();
            updateTeamList();
            modelRef.experienceTo = parseInt(modelRef.experienceTo);
            modelRef.experienceFrom = parseInt(modelRef.experienceFrom);
            modelRef.value = parseInt(modelRef.value);
            modelRef.startDate = Utils.displayYearFirstNew(modelRef.startDate);
            modelRef.displayStartDate = Utils.displayYearFirstNew(
              modelRef.displayStartDate
            );
            modelRef.displayEndDate = Utils.displayYearFirstNew(
              modelRef.displayEndDate
            );
            modelRef.cic = cicRef.cicList;
            modelRef.teams = teamRef.teamList;
            modelRef.verticals = [modelRef.verticals];
            modelRef.practiceAreas = [modelRef.practiceAreas];
            modelRef.subPracticeAreas = modelRef.subPracticeAreas
              ? [modelRef.subPracticeAreas]
              : delete modelRef.subPracticeAreas;
            modelRef.originatedCIC
              ? modelRef.originatedCIC
              : delete modelRef.originatedCIC;
            delete modelRef.team1Id;
            delete modelRef.subteam1Id;
            delete modelRef.leadCIC;
            Services.createVacancy(modelRef)
              .then((response) => {
                const { data } = response;
                message.success("Vacancy created successfully");
                let id = data.data.id;
                router.push(`/vacancies/${id}`);
              })
              .catch((err) => {});
          })
          .catch((err) => {});
      }
    };

    return {
      validate,
      validateInfos,
      resetFields,
      modelRef,
      formRef,
      onSubmit,
      // Select Options Variables
      cityRespones,
      company_name_options,
      client_person_options,
      vacancyTypeResponse,
      vacancyStatusesResponse,
      originatedByResponse,
      cicOptions,
      verticalResponse,
      practiceAreaResponse,
      subPracticeAreaResponse,
      designationLevelOptions,
      termsFeesOptions,
      teamOptions,
      subTeamOptions,
      // Functions
      fetchCompanyList,
      fetchClientContactandTerms,
      fetchCicList,
      fetchVerticalData,
      fetchpracticeAreas,
      fetchsubPracticeAreas,
      fetchTeamsData,
      fetchSubTeamsData,
      updateValue,
      onClickExit,
      manageCICRevenueShare,
      manageTeamRevenueShare,
      originatedByChange,
      experienceValidation,
      disabledStartDate,
      disabledEndDate,
      cicRef,
      teamRef,
      // Revenue Status
      disableCICRev2,
      disableCICRev1,
      disableCICRev3,
      disableTeamRev2,
      disableTeamRev3,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../styles/createNewVacancy.scss";
</style>
